export default [
    {
        nombre: "SAVIA Semillas",
        rubro: '  Ventas de semillas',
        descripcion: "Especialista en Metabolismo de los Cannabinoides y Fitocannabinoides, Chef molecular y amante de los Extractos con y sin solventes.",
        web: "https://saviasemilla.empretienda.com.ar/",
        instagram: "https://www.instagram.com/bryanramost/",
        facebook: null,
        x: null,
        foto: require("./img/savia.jpg"),
        sector: '  Industria y cultivo',
        stand: 'Plata',
        sponsor: 'Plata',
        puntaje: 2,
        show: true
    },    
    {
        nombre: "KIEV Ingeniería de cultivos",
        rubro: '  Iluminación',
        descripcion: "Ofrecen una gama cuidadosamente seleccionada de productos, incluyendo lámparas de cultivo, humidificadores y otros artículos esenciales.",
        web: "https://kievcultivodeplantas.com/",
        instagram: "https://www.instagram.com/kievingenieria/",
        facebook: null,
        x: null,
        foto: require("./img/Kiev.png"),
        sector: '  Industria y cultivo',
        stand: 'Plata',
        sponsor: null,
        puntaje: 1,
        show: true
    },
    {
        nombre: "Fábrica Chiatti",
        rubro: '  Industria, fabricación de maquinaria para la manufactura del cannabis.',
        descripcion: "Se dedican a la industria y fabricación de maquinaria para la manufactura del cannabis.",
        web: null,
        instagram: "https://www.instagram.com/fabrica_chiatti/",
        facebook: "https://www.facebook.com/chiatti.chiatti",
        x: null,
        foto: require("./img/Chiatti.png"),
        sector: '  Industria y cultivo',
        stand: 'Plata',
        sponsor: null,
        puntaje: 1,
        show: true
    },
    {
        nombre: "Volcano Group",
        rubro: '  Vaporizadores de hierbas secas legales.',
        descripcion: "Se dedican a la distribución exclusiva de Storz & Bickel, pionera en vaporizadores herbales en el mundo. Cuentan con todos los productos de la marca Alemana para ventas minoristas y mayoristas, brindan una atención personalizada con capacitación constante.",
        web: "https://volcanogroup.com.ar/",
        instagram: "https://www.instagram.com/volcanogroup.ar/",
        facebook: null,
        x: null,
        foto: require("./img/Volcano.png"),
        sector: '  Industria y cultivo',
        stand: 'Plata',
        sponsor: 'Plata',
        puntaje: 2,
        show: true
    },
    {
        nombre: "Laboratorio HQA",
        rubro: '  Laboratorio',
        descripcion: "HqA es un laboratorio, que atreves de un equipo altamente capacitado, elabora PRODUCTOS NATURALES. cuidando el proceso en su máxima expresión, para obtener la mejor calidad del producto. nuestro objetivo: Lograr que usted se sienta cuidado, seguro y confiado. Director de laboratorio Químico Industrial: Héctor Orlando Argüello",
        web: "https://www.laboratoriohqa.com.ar/",
        instagram: "https://www.instagram.com/laboratoriohqa.ok/",
        facebook: null,
        x: null,
        foto: require("./img/HqA.png"),
        sector: '  Medicinal',
        stand: 'Plata',
        sponsor: null,
        puntaje: 1,
        show: true
    },
    {
        nombre: "Estudio 420",
        rubro: '  Estudio jurídico de cannabis',
        descripcion: "Estudio 420, el primer estudio jurídico dedicado a la temática cannabis. Una defensa legal especializada y de calidad para todos los procedimientos penales y administrativos en los que pueda verse inmerso como consumidor o asociación de cannabis. A todos aquellos que estén en la búsqueda y carecen de herramientas jurídicas los orientan y respaldan el proyecto en el que quieran embarcarse unidos como profesionales de diferentes ámbitos, pero especializados en lo que denominamos Derecho Cannábico. Reservan un tiempo dedicado significativamente a causas sociales vinculadas al cannabis. Desde iniciativas parlamentarias, manifestaciones públicas y la actuación ante los tribunales nacionales.",
        web: "https://estudio4veinte.com/",
        instagram: null,
        facebook: null,
        x: null,
        foto: require("./img/Estudio 420.jpg"),
        sector: '  Industria y cultivo',
        stand: 'Diamante',
        sponsor: 'Diamante',
        puntaje: 6,
        show: true
    },
    {
        nombre: "CANNATECH",
        rubro: '  Club ONG  + Grow + FoodCann',
        descripcion: "ONG dedicada al cultivo, extracción , procesamiento de materia vegetal mediante métodos tecnológicos y de alta calidad. Con genéticas exclusivas y estabilizadas por expertos.",
        web: null,
        instagram: null,
        facebook: null,
        x: null,
        foto: require("./img/cannatech.jpeg"),
        sector: '  Industria y cultivo',
        stand: 'Plata',
        sponsor: 'Plata',
        puntaje: 2,
        show: true
    },
    {
        nombre: "Insativa",
        rubro: '  Iluminación ',
        descripcion: "Luces para plantas.",
        web: 'https://www.insativa.com.ar/',
        instagram: 'https://www.instagram.com/insativa.ar/',
        facebook: null,
        x: null,
        foto: require("./img/INSATIVA.png"),
        sector: '  Industria y cultivo',
        stand: 'Oro',
        sponsor: 'Oro',
        puntaje: 4,
        show: true
    },
    {
        nombre: "Semishop ",
        rubro: '  Distribuidor de semillas  ',
        descripcion: "Gracias a nuestro amplio catálogo llevamos alegría y medicina de manera segura y confiable a todos los usuarios de cannabis medicinal.",
        web: 'https://autocultivosemishop.com/',
        instagram: 'https://www.instagram.com/autocultivo_semishop/',
        facebook: null,
        x: null,
        foto: require("./img/semishop.png"),
        sector: '  Industria y cultivo',
        stand: 'Oro',
        sponsor: null,
        puntaje: 3,
        show: false
    },
    {
        nombre: "Cultivate Sustratos",
        rubro: '  Insumos para el cultivador',
        descripcion: "Somos una empresa que fabrica sustratos de autor registrados en senasa específicos para el cultivo de cannabis.",
        web: 'https://www.cultivatesustratos.com/',
        instagram: 'https://www.instagram.com/cultivatesustratos/',
        facebook: null,
        x: null,
        foto: require("./img/CULTIVATE.jpg"),
        sector: '  Industria y cultivo',
        stand: 'Diamante',
        sponsor: null,
        puntaje: 5,
        show: true
    },  
    {
        nombre: "Anfibio Semillas",
        rubro: '  Semillas',
        descripcion: "Distribuidor de semillas de bancos nacionales.  Somos el banco de bancos.",
        web: 'https://anfibiosemillas.com/',
        instagram: 'https://www.instagram.com/anfibiosemillas/',
        facebook: 'https://www.facebook.com/people/Anfibio-Semillas/pfbid033datRfaVJY9iXupZEWM734VC8Qc4JVueoi1J8aSk6e6baCT38CsBhYjnHiXGbJ4ol/',
        x: null,
        foto: require("./img/Anfibio Semillas.png"),
        sector: '  Industria y cultivo',
        stand: 'Plata',
        sponsor: null,
        puntaje: 1,
        show: true
    },
    {
        nombre: "Arahandseeds",
        rubro: '  Cannabis medicinal ',
        descripcion: 'Arahandseeds es un banco nacional con presencia internacional ,nos diferenciamos por nuestras genéticas originales y nuestro aporte al autocultivo. Nuestra bandera es el cannabis medicinal y el uso responsable por eso creamos nuestro propio CLUB DE CULTIVO "BLAK 47" Cremos que el cannabis es una industria potente y saludable. Esperamos poder seguir avanzando en la investigación de nuevas genéticas para brindar mayores soluciones a la inmensa diversidad de consumidores del cannabis.',
        web: null,
        instagram: 'https://www.instagram.com/arahandseedsargentina/',
        facebook: null,
        x: null,
        foto: require("./img/arahand.jpg"),
        sector: '  Industria y cultivo',
        stand: 'Diamante',
        sponsor: 'Diamante',
        puntaje: 6,
        show: true
    },
    {
        nombre: "Mars",
        rubro: '  Electronica',
        descripcion: 'Mars es una empresa nacional que se dedica al diseño y fabricacion de luminaras Led de alta eficiencia para el cultivo indoor y outdoor. Poseemos mas de una docena de modelos y contamos con una red profesionales para asesoramiento, medicion y seguimiento de los cultivos.',
        web: 'https://www.mars.com.ar/',
        instagram: 'https://www.instagram.com/marsledsgrow/',
        facebook: null,
        x: null,
        foto: require("./img/mars.jpg"),
        sector: '  Industria y cultivo',
        stand: 'Oro',
        sponsor: 'Oro',
        puntaje: 4,
        show: true
    }, 
    {
        nombre: "Genética La Maga",
        rubro: '  Genética: semillas y clones registrados',
        descripcion: 'Genética La Maga es un criadero habilitado por el Registro Nacional de Comercialización y Fiscalización de Semillas con numerosas genéticas inscriptas de cannabis sativa l. en el catálogo naciona de cultivares. Comercializa clones y semillas a Reprocannes, growshops (categoría F), Multiplicadores (categorías K), y proyectos productivos habilitados.',
        web: 'https://lamaga.ar/',
        instagram: 'https://www.instagram.com/geneticaslamaga/',
        facebook: null,
        x: null,
        foto: require("./img/LaMaga.png"),
        sector: '  Industria y cultivo',
        stand: 'Plata',
        sponsor: null,
        puntaje: 1,
        show: true
    },
    {
        nombre: "FECCA",
        rubro: '  Federacion de clubes cannabicos',
        descripcion: 'FECCA es la Federación de Clubes Cannábicos de la Argentina. Apuestan al fortalecimiento del modelo de clubes c4nnábicos de membresía como vía de acceso legal a cannabis medicinal, seguro y de calidad.',
        web: 'https://fecca.com.ar/',
        instagram: 'https://www.instagram.com/fecca.oficial?igsh=MTQ4N25saWhxbXhiNw==',
        facebook: null,
        x: null,
        foto: require("./img/FECCA.png"),
        sector: '  Industria y cultivo',
        stand: 'Diamante',
        sponsor: 'Diamante',
        puntaje: 6,
        show: true
    },
    {
        nombre: "INCA distribuidora",
        rubro: '  Distribuidor',
        descripcion: 'Distribuidor de tabaco y parafernalia.',
        web: 'https://incadistribuciones.com.ar/',
        instagram: 'https://www.instagram.com/inca.distribuciones.mendoza/',
        facebook: 'https://www.facebook.com/people/Inca-Distribuciones/100070020406866/',
        x: null,
        foto: require("./img/inca.jpeg"),
        sector: '  Industria y cultivo',
        stand: 'Diamante',
        sponsor: null,
        puntaje: 5,
        show: true
    },
    {
        nombre: "Planta carnivura",
        rubro: '  Growshop ',
        descripcion: 'Somos un growshop que brinda asesoramiento y venta de insumos para autocultivo de cannabis.',
        web: null,
        instagram: 'https://www.instagram.com/plantacarnivura',
        facebook: 'https://www.facebook.com/people/Inca-Distribuciones/100070020406866/',
        x: null,
        foto: require("./img/PlantaCarnivura.jpg"),
        sector: '  Industria y cultivo',
        stand: 'Plata',
        sponsor: null,
        puntaje: 1,
        show: true
    },
    {
        nombre: "GREENFIRE",
        rubro: '  Sustratos ',
        descripcion: 'Empresa dedicada a la elaboración de sustratos profesionales apto para todo tipo de cultivos.',
        web: 'http://www.greenfiresf.com',
        instagram: 'https://www.instagram.com/greenfiresf/',
        facebook: 'https://www.facebook.com/GreenFireSF',
        x: null,
        foto: require("./img/GREENFIRE.png"),
        sector: '  Industria y cultivo',
        stand: 'Plata',
        sponsor: null,
        puntaje: 1,
        show: true
    },
    {
        nombre: "UNGE CULTIVOS SOSTENIBLES",
        rubro: '  TURISMO CANNABICO / PRODUCCION DE FLORES A ESCALA INDUSTRIAL',
        descripcion: 'Somos una empresa dedicada a tres grandes unidades de negocios. La producción e investigación de inflorescencias de Cannabis Sativa L. con fines medicinales, el desarrollo del turismo cannábico de experiencia y la producción de cañamo industrial con fines alimenticios. Buscamos abastecer una industria transformadora y en constante crecimiento mediante procesos productivos, eficientes y sostenibles. Con el fin de promover la salud, el bienestar y una mejor calidad de vida.',
        web: 'https://ungesa.com',
        instagram: 'https://www.instagram.com/unge.arg/',
        facebook: 'https://www.facebook.com/ungecultivossostenibles/',
        x: null,
        foto: require("./img/UNGE.png"),
        sector: '  Industria y cultivo',
        stand: 'Plata',
        sponsor: 'Plata',
        puntaje: 2,
        show: true
    },
    {
        nombre: "Deep Neville",
        rubro: '  Semillas',
        descripcion: 'Distribuidora de Semillas para todo el país, con más de 15 bancos internacionales y 3 bancos nacionales con semillas registradas.',
        web: 'https://deepneville.com/',
        instagram: 'https://www.instagram.com/deep_neville/',
        facebook: null,
        x: null,
        foto: require("./img/DeepNeville.jpg"),
        sector: '  Industria y cultivo',
        stand: 'Plata',
        sponsor: null,
        puntaje: 1,
        show: true
    },
    // {
    //     nombre: "Green Feeling",
    //     rubro: '  Grow shop',
    //     descripcion: 'Somos una empresa dedicada a la venta de insumos para cannabis.',
    //     web: null,
    //     instagram: 'https://www.instagram.com/greenfeelingmza/',
    //     facebook: null,
    //     x: null,
    //     foto: require("./img/GreenFeeling.jpeg"),
    //     sector: '  Industria y cultivo',
    //     stand: 'Plata',
    //     sponsor: null,
    //     puntaje: 1,
    //     show: false
    // },
    {
        nombre: "MENDOCINO MOUNTAIN",
        rubro: '  Servicios agrarios, consulting, fitomejora genética, importación y exportación.',
        descripcion: 'Creamos y mejoramos genéticas de calidad para todo tipo cultivo y brindamos asesoramiento integral: Diseño y optimización de espacios de cultivo y asesoramiento en todos los procesos desde las semilla hasta la cosecha y venta. Somos distribuidores oficiales de Lumatek Lighting.',
        web: 'www.mendocinomountain.com',
        instagram: 'https://www.instagram.com/mendocino_mountain',
        facebook: null,
        x: null,
        foto: require("./img/Mendocino Mountain.jpg"),
        sector: '  Industria y cultivo',
        stand: 'Oro',
        sponsor: null,
        puntaje: 3,
        show: true
    },
    {
        nombre: "THE FARM",
        rubro: '  Cultivo de Cannabis Medicinal.',
        descripcion: 'Asociación civil de cultivadores solidarios de cannabis medicinal.',
        web: null,
        instagram: 'https://www.instagram.com/thefarm.argentina/',
        facebook: null,
        x: null,
        foto: require("./img/TheFarm.jpg"),
        sector: '  Industria y cultivo',
        stand: 'Plata',
        sponsor: null,
        puntaje: 1,
        show: true
    },
    {
        nombre: "KCBD CORP",
        rubro: '  PRODUCTIVO',
        descripcion: null,
        web: 'https://kcbd.com.ar/',
        instagram: null,
        facebook: null,
        x: null,
        foto: require("./img/Kcbd.png"),
        sector: '  Medicinal',
        stand: 'Plata',
        sponsor: null,
        puntaje: 1,
        show: true
    },  
    {
        nombre: "Ojitos Rojos",
        rubro: 'Comercialización de semillas ',
        descripcion: 'Distribuidora de semillas de cannabis ',
        web: 'https://ojitosrojos.com/',
        instagram: 'https://www.instagram.com/somosojitosrojos/',
        facebook: null,
        x: null,
        foto: require("./img/ojitos rojos.png"),
        sector: '  Industria y cultivo',
        stand: 'Plata',
        sponsor: null,
        puntaje: 1,
        show: true
    },   
    {
        nombre: "Highprotek Hydroponics",
        rubro: 'Distribuidor',
        descripcion: 'Semillas de colección. Trabajamos los mejores bancos.',
        web: null,
        instagram: 'https://www.instagram.com/highprotek/',
        facebook: null,
        x: null,
        foto: require("./img/Cannagami Seeds.png"),
        sector: '  Industria y cultivo',
        stand: 'Plata',
        sponsor: null,
        puntaje: 1,
        show: true
    },
    {
        nombre: "Ciclo cultivo",
        rubro: 'Iluminación',
        descripcion: 'Iluminación ultra eficiente para salas e invernaderos. Trabajamos con LEDs cerámicos y Espectro Vital.',
        web: 'www.ciclocultivo.com.ar',
        instagram: 'https://www.instagram.com/panoptic.grow/',
        facebook: null,
        x: null,
        foto: require("./img/Luciano Castelo.png"),
        sector: '  Industria y cultivo',
        stand: 'Plata',
        sponsor: 'Plata',
        puntaje: 2,
        show: true
    }, 
    {
        nombre: "Cerveza 420",
        rubro: 'Bebidas',
        descripcion: 'Somos un equipo cannabico cervecero en constante desarrollo unidos por la pasión que nos unen, con el objetivo de ofrecer.',
        web: 'https://cerveza420.com/',
        instagram: 'https://www.instagram.com/cerveza.420/',
        facebook: null,
        x: null,
        foto: require("./img/Weed & Beer.jpg"),
        sector: '  Industria y cultivo',
        stand: 'Plata',
        sponsor: null,
        puntaje: 1,
        show: true
    }, 
    {
        nombre: "Chita seeds",
        rubro: 'Banco de genéticas.',
        descripcion: 'Chita seeds es el primer Banco de genéticas autoflorecientes de Argentina, nuestra especialidad es crear genéticas de rápido crecimiento con ciclos cortos tanto para cultivos personales como industriales. Durante más de una década, nos dedicamos a la mejora de variedades autoflorecientes.',
        web: 'https://chitaseeds.com/',
        instagram: 'https://www.instagram.com/chita.seeds/',
        facebook: null,
        x: null,
        foto: require("./img/Flavio Slama.jpg"),
        sector: '  Industria y cultivo',
        stand: 'Plata',
        sponsor: null,
        puntaje: 1,
        show: true
    },
    {
        nombre: "geostock",
        rubro: 'Agro',
        descripcion: 'Aglomerado familiar de 3 empresas de Mendoza, contando con más de 50 años de experiencia en el sector agropecuario.',
        web: 'https://geostock.net/',
        instagram: 'https://www.instagram.com/geostock.arg/',
        facebook: null,
        x: null,
        foto: require("./img/Gerardo Gaia.png"),
        sector: '  Industria y cultivo',
        stand: 'Plata',
        sponsor: null,
        puntaje: 1,
        show: true
    }    
]